import React from "react"
import { HStack, Text } from "@chakra-ui/react"
import { useShopifyPriceRange } from "@app/hooks/useShopify"

type Props = {
  product: any
  loading?: boolean
}

const ProductPriceRange: React.FC<Props> = ({ product, loading }) => {
  const { formattedMinPrice } = useShopifyPriceRange(product)

  return !loading ? (
    <HStack mt={2}>
      <Text size="smSemiLarge" color="typography.headlines900" textTransform="uppercase">
        {formattedMinPrice}
      </Text>
    </HStack>
  ) : null
}

export default React.memo(ProductPriceRange)
